<template>
	<div class="useredit_top">
		<van-nav-bar
		class="nav_bar_color"
		title="个人信息"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>

		<img class="user_img_absole" src="http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/useredit_bj.png" mode="">
		<div class="user_top">
			<div class="user_top_img">
				<van-uploader :before-read="before" :after-read="uploadavatar">
					<van-image class="img_avatar" round :src="serverUrl(form.avatar)" fit="cover"/>
				</van-uploader>
				<img class="absole_img" src="@/assets/user_edit/icom_upload.png" mode="">
			</div>
			<div class="user_top_user">
				{{form.username}}
			</div>
		</div>
		
		<input class="input_phone" v-model="form.contact" placeholder="点击输入联系电话" />
		<input class="input_phone" v-model="form.bio" placeholder="点击输入个人口号" />
		
		<div class="user_wxupload">
			<div class="user_wxupload_title">
				企业微信二维码
			</div>
			<div class="user_wxupload_content">
				请上传您的企业微信二维码
			</div>
			<van-uploader :before-read="before" :after-read="uploadimg">
			<div class="user_wxupload_upload">
				<img v-if="form.wechat_qr_image != null && form.wechat_qr_image != ''" class="user_wximg" :src="form.wechat_qr_image" mode="">
				<div v-else class="user_wxupload_upload_content">
					<img src="@/assets/common/icon_tianjia.png" mode="">
					<span>点击上传</span>
					<span>企业微信二维码</span>
				</div>
			</div>
		</van-uploader>
		</div>
		
		<div class="user_footer"  style="height: 91px;" :style="{'top':(docmHeight - 91) + 'px'}">
			<div class="" @click="save">
				保存个人信息
			</div>
			
		</div>
		<van-loading class="" size="24px" type="spinner" vertical>加载中...</van-loading>

	</div>
</template>

<script>
// import WxNavigationBar from '@/components/wxnavigationbar/wxnavigationbar.vue'
// import WxNavigationBarSeat from '@/components/wxnavigationbarseat/wxnavigationbarseat.vue'	
	
	export default {
		// components: {
		// 	WxNavigationBar,
		// 	WxNavigationBarSeat,
		// },
		data() {
			return {
				loading:false,
				form:{
					username:'',
					avatar:'',
					contact:'',
					wechat_qr_image:null,
					bio:''
				},
				imgs:'',
				userinfo:{},
				// 用户详情
				
			};
		},
		computed: {    
			docmHeight() {
				console.log(this.$store.state.height)
				return this.$store.state.height;
			}
		},
		mounted(){
			this.geiuserinfo()
		},
		methods:{
			// 判断类型
			before(file,detail){
				this.loading = true
				if(file.type.slice(0,5) == 'image'){
					return true
				}else{
					this.$toast.fail('请上传图片文件')
				}
			},
			// 上传头像
			async uploadavatar(e){
				let formData = new FormData();//通过formdata上传
				formData.append('file', e.file)
				const {data:res} = await this.$http.post('/api/alioss/upload',formData)
				if(res.code == 1){
					this.form.avatar = res.data.res.url
				}else{
					this.$toast(res.msg)
				}
				this.loading = false
			},
			// 上传二维码
			async uploadimg(e){
				let formData = new FormData();//通过formdata上传
				formData.append('file', e.file)
				const {data:res} = await this.$http.post('/api/alioss/upload',formData)
				if(res.code == 1){
					const {data:res4} = await this.$http.post('/api/scsj/qrcode_rebuild',{image:res.data.res.fullurl + '?x-oss-process=style/SMALLPIC'})
						if(res4.code == 1){
							this.form.wechat_qr_image = res4.data.res.fullurl
							this.imgs = res.data.res.url
							this.$toast.success('上传成功')
						}else{
							this.$toast.fail(res4.msg)
						}
						this.loading = false
				}
			},
			// 保存个人信息
			async save(){
				if(this.imgs){
					this.form.wechat_qr_image = this.imgs
				}else{
					Reflect.deleteProperty(this.form,'wechat_qr_image')
				}
				const {data:res} = await this.$http.post('/api/user/edit_userinfo_1_1',this.form)
				if(res.code == 1){
					this.$toast.success('保存成功')
					this.$router.go(-1)
				}else{
					this.$toast.fail(res.msg,'error')
				}
			},
			
			async geiuserinfo(){
				const {data:res} = await this.$http.post('/api/user/get_userinfo_1_5_1')
				if(res.code == 1){
					this.form.avatar = res.data.res.avatar
					this.form.contact = res.data.res.contact
					this.form.wechat_qr_image = res.data.res.wechat_qr_image
					this.form.bio = res.data.res.bio
					this.form.username = res.data.res.username
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
.loading{
	position: fixed;
	left: calc(50% - 75px) ;
	top: calc(50% - 75px);
	width: 110px;
	padding: 20px;
	height: 110px;
	display: flex;
	align-items: center;
	background-color: #191919;
}
.useredit_top{
	box-sizing: border-box;
	background-color: #191919;
	padding-top: 290px;
	padding-bottom: 500px;
}
.user_img_absole{
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
	}
.user_top{
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 200px;
	align-items: center;
	.user_top_user{
		font-family:HYQiHeiY3-55;
		z-index: 1;
		font-weight: normal;
		margin-top: 20px;
		font-size: 40px;
		line-height: 40px;
		padding-bottom: 40px;
		color: #C7C7C7;
	}
	.user_top_img{
		position: relative;
		.img_avatar{
			height: 152px;
			width: 152px;
			border-radius: 50%;
		}
		.absole_img{
			position: absolute;
			height: 52px;
			width: 156px;
			left: 0;
			bottom: 0;
		}
	}
}

.input_phone{
	margin: 30px 90px 0;
	height: 100px;
	width: 550px;
	text-align: center;
	border: 1px solid #6F6F6F;
	color: #fff;
	font-size: 36px;
	background-color: #191919;
	font-family: HYQiHeiY3-45;
	font-weight: normal;
}

.user_wxupload{
	margin-top: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: HYQiHeiY3-45;
	.user_wxupload_title{
		font-size: 40px;
		font-weight: normal;
		color: #FFFFFF;
		line-height: 40px;
		padding: 20px 0;
	}
	.user_wxupload_content{
		font-size: 28px;
		font-weight: normal;
		color: #878787;
		line-height: 28px;
		padding: 20px 0;
	}
	.user_wxupload_upload{
		position: relative;
		margin-top: 40px;
		background: #3E3E3E;
		width: 236px;
		height: 236px;
		.user_wximg{
			width: 236px;
			height: 236px;
			
		}
		.user_wxupload_upload_content{
			position: absolute;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 236px;
			height: 236px;
			padding: 50px 0 0 0;
			top: 0;
			left: 0;
			img{
				width: 48px;
				height: 48px;
			}
			span{
				font-size: 28px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #ECECEC;
				line-height: 50px;
			}
		}
	}
}

.user_footer{
	position: fixed;
	bottom: 0;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 750px;
	background: #000000;
	text-align: center;
	div{
		width: 670px;
		height: 92px;
		background: #FA0037;
		text-align: center;
		line-height: 92px;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
	}
}
</style>
